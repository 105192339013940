/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';
import { ModuleType } from 'app/models/module';
import { Module } from 'module';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'dashboards',
        title: 'DASHBOARD',
        subtitle: 'Management Dashboard & Analytics',
        type: 'group',
        icon: 'heroicons_outline:home',
        meta:{modules: [ModuleType.DASHBOARD]},
        children: [
            {
                id: 'dashboards.analytics',
                title: 'Analytics',
                type: 'basic',
                icon: 'heroicons_outline:chart-pie',
                link: '/dashboards/analytics',
                meta:{modules: [ModuleType.DASHBOARD]},
            },
        ],
    },
    {
        id: 'swims',
        meta:{modules: [ModuleType.PRINTING,ModuleType.STOCK_ADJUSTMENT,ModuleType.REPORTS,ModuleType.USER_AND_ROLE_MANAGEMENT,ModuleType.TUNNEL_SCANNING]},
        title: 'SWIMS',
        subtitle: 'SWIMS Common modules',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'swims-common.printing',
                meta:{modules: [ModuleType.PRINTING]},
                title: 'Printing',
                type: 'collapsable',
                icon: 'heroicons_outline:printer',
                children: [
                    {
                        id: 'swims-common.printing.tag-generation',
                        meta:{modules: [ModuleType.TAG_GENERATION,ModuleType.PRINT_TAGS]},
                        title: 'Tag Generation',
                        type: 'basic',  
                        link: '/swims-common/printing/tag-generation',
                        pageField: [
                            { colCode: 'epc', colName: 'EPC', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'pONumber', colName: 'PO Number', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'itemName', colName: 'Item Name', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'brandCode', colName: 'Brand Code', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'skuCode', colName: 'SKU', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'statusName', colName: 'Status', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                        ]
                    },
                    {
                        id: 'swims-common.printing.po-printing',
                        meta:{modules: [ModuleType.PO_PRINTING,ModuleType.PRINT_TAGS]},
                        title: 'PO Printing',
                        type: 'basic',
                        link: '/swims-common/printing/po-printing',
                        pageField: [
                          
                            { colCode: 'poNo', colName: 'PO Number', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'brandCode', colName: 'Brand Code', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'articleNo', colName: 'Article No', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'orderDate', colName: 'Order Date', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'deliveryDate', colName: 'Delivery Date', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'tagGeneratedDate', colName: 'Last Tag Generated Date', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'printedQty', colName: 'Printed/Associated', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                           
                           
                        ]
                    },
                    
                ]
            },
            {
                id: 'swims-common.printing.epc-import',
                meta:{modules: [ModuleType.ITEM_MASTER_MANAGEMENT]},
                title: 'Inventory Upload',
                icon: 'heroicons_outline:arrow-up-tray',
                type: 'basic',
                link: '/swims-common/printing/epc-import',
                pageField: [
                  
                    { colCode: 'epc', colName: 'EPC', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'sku', colName: 'SKU', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'articleNo', colName: 'Article', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'itemName', colName: 'Item Name', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'collectionName', colName: 'Collection Name', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'sellPrice', colName: 'Sell Price', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'colorName', colName: 'Color', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'sizeCode', colName: 'Size', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'createdOn', colName: 'Cerated On', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                   
                   
                ]
            },
            {
                id: 'swims-common.stockadjustment',
                meta:{modules: [ModuleType.STOCK_ADJUSTMENT]},
                title: 'Stock Adjustment',
                type: 'basic',
                icon: 'heroicons_outline:adjustments-horizontal',
                link: '/swims-common/stockadjustment',
                pageField: [
                    { colCode: 'txnNo', colName: 'Stock Adjustment No.', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'submitter', colName: 'Raised By', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'submittedOn', colName: 'Date Raised', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'locationName', colName: 'Location', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'stockAdjustmentType', colName: 'Type', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'statusName', colName: 'Status', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                ]
            },
            {
                id: 'swims-common.report',
                meta:{modules: [ModuleType.REPORTS]},
                title: 'Report',
                type: 'basic',
                icon: 'heroicons_outline:document-chart-bar',
                link: '/swims-common/report',
                pageField: [

                ]
            },
            {
                id: 'swims-common.user',
                meta:{modules: [ModuleType.USER_AND_ROLE_MANAGEMENT]},
                title: 'User And Role Management',
                type: 'collapsable',
                icon: 'heroicons_outline:user-group',
                children: [
                    {
                        id: 'swims-common.user.usermanagement',
                        meta:{modules: [ModuleType.USER_MANAGEMENT]},
                        title: 'User Management',
                        type: 'basic',
                        //icon: 'heroicons_outline:rocket-launch',
                        link: '/swims-common/user/usermanagement',
                        pageField: [
                            { colCode: 'fullName', colName: 'Name', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'email', colName: 'Email', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'userTypeCode', colName: 'User Type', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'userRoleName', colName: 'Role', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'allBrandsAccess', colName: 'All Brand Access', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                        ]
                    },
                    {
                        id: 'swims-common.user.rolemanagement',
                        meta:{modules: [ModuleType.ROLE_MANAGEMENT]},
                        title: 'Role Management',
                        type: 'basic',
                        //icon: 'heroicons_outline:rocket-launch',
                        link: '/swims-common/user/rolemanagement',
                        pageField: [
                            { colCode: 'name', colName: 'Role', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'isSupplierUser', colName: 'Supplier User', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'isRetailUser', colName: 'Retail User', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'isActive', colName: 'Active', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                        ]
                    }        
                ]
            },
            {
                id: 'swims-common.tunnelscanning',
                title: 'Tunnel Scanning',
                meta:{modules: [ModuleType.TUNNEL_SCANNING]}, // need for tunnel scanning
                type: 'collapsable',
                icon: 'heroicons_outline:queue-list',
                children: [
                    {
                        id: 'swims-common.tunnelscanning.goodsinbound',
                        title: 'Goods Inbound',
                        meta:{modules: [ModuleType.GOODS_INBOUND]}, 
                        type: 'basic',
                        link: '/swims-common/tunnelscanning/goodsinbound',
                        pageField: [
                            { colCode: 'poNumber', colName: 'PO No.', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 100 },
                            { colCode: 'displayQty', colName: 'Scanned / Expected Qty', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 100 },
                            { colCode: 'countryCode', colName: 'CountryCode', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 100 },
                            { colCode: 'brandCode', colName: 'Brand', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 100 },
                            { colCode: 'lastReceivedOn', colName: 'Last Inbound Date', requiredForUpload: true,isDateField:true, hidden: false, checked: true, sortable: true, width: 100 },
                            { colCode: 'latestTagGeneratedDate', colName: 'Last Tag Generated Date', requiredForUpload: true,isDateField:true, hidden: false, checked: true, sortable: true, width: 100 },
                            { colCode: 'status', colName: 'Status', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 100 },
                        ]
                    },
                    // Temporary disable this features until developed.
                    // {
                    //     id: 'swims-common.tunnelscanning.adhoc',
                    //     title: 'Ad-Hoc',
                    //     type: 'basic',
                    //     link: '/swims-common/tunnelscanning/adhoc',
                    // },
                    // {
                    //     id: 'swims-common.tunnelscanning.movement',
                    //     title: 'Movement',
                    //     type: 'basic',
                    //     link: '/swims-common/tunnelscanning/movement',
                    // }
                ]
            },
        ],
    },
    {
        id: 'swims+',
        meta:{modules: [ModuleType.ORDER_PLANNING,ModuleType.STOCK_RETURN,ModuleType.STOCK_TRANSFER,
            ModuleType.BRAND_MANAGEMENT,ModuleType.COUNTRY_MANAGEMENT,ModuleType.MALL_MANAGEMENT,ModuleType.WAREHOUSE_LOCATION_MANAGEMENT,
            ModuleType.ITEM_CATEGORY_MANAGEMENT,ModuleType.ITEM_CATEGORY_VARIANCE_MANAGEMENT,ModuleType.ITEM_STYLE_MANAGEMENT, ModuleType.PALLET_MANAGEMENT,
            ModuleType.SUPPLIER_MANAGEMENT,ModuleType.ITEM_SIZE_MANAGEMENT,ModuleType.STORE_MANAGEMENT,,ModuleType.SELL_PRICE_MANAGEMENT,
            ModuleType.EXCHANGE_RATE_MANAGEMENT,ModuleType.COLLECTION_MANAGEMENT,ModuleType.TAG_MANAGEMENT,ModuleType.MEASUREMENT_MANAGEMENT
        ]},
        title: 'SWIMS+',
        subtitle: 'SWIMS Extended modules',
        type: 'group',
        icon: 'heroicons_outline:home',
        children: [
            {
                id: 'swims.orderplanning',
                meta:{modules: [ModuleType.ORDER_PLANNING]},
                title: 'Order Planning',
                type: 'basic',
                icon: 'heroicons_outline:shopping-bag',//document-text
                link: '/swims/orderplanning',
                pageField: [
                    { colCode: 'purchaseOrderNumber', colName: 'Purchase Order Number', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 150 },
                    { colCode: 'articleNumber', colName: 'Article Number', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 150 },
                    { colCode: 'brand', colName: 'Brand', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 150 },
                    { colCode: 'countryCode', colName: 'Country Code', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 150 },
                    { colCode: 'requiredQty', colName: 'Required Quantity', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 150 },
                    { colCode: 'statusName', colName: 'Order Status', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'updatedByName', colName: 'Last Edited By', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 150 },
                    { colCode: 'updatedOn', colName: 'Last Edited Date', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 150 },
                    { colCode: 'firstSubmittedOn', colName: 'Date Submitted', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 150 },
                ],
            },
            {
                id: 'swims.stockreturn',
                meta:{modules: [ModuleType.STOCK_RETURN]},
                title: 'Stock Return',
                type: 'basic',
                icon: 'heroicons_outline:arrow-uturn-left',
                link: '/swims/management/stockreturn',
                pageField: [
                    { colCode: 'txnNo', colName: 'Transaction Number', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'txnName', colName: 'Transaction Name', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'countryName', colName: 'Country', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'submitter', colName: 'Submitter', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'submittedOn', colName: 'Submitted On', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'approver', colName: 'Approver', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'approvedRejectedOn', colName: 'Approved Rejected On', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'approverRemarks', colName: 'Approver Remarks', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'returnedQty', colName: 'Returned Qty', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'statusName', colName: 'Status', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 250 }],
            },
            {
                id: 'swims.stocktransfer',
                meta:{modules: [ModuleType.STOCK_TRANSFER]},
                title: 'Stock Transfer',
                type: 'basic',
                icon: 'heroicons_outline:truck',
                link: '/swims/stocktransfer',
                pageField: [
                    { colCode: 'txnNo', colName: 'Transaction Number', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'txnName', colName: 'Transaction Name', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'countryName', colName: 'Country', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'location', colName: 'Location', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'transferType', colName: 'Transfer Type', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'collectionName', colName: 'Collection Name', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'submitter', colName: 'Submitter', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'submittedOn', colName: 'Submitted On', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'transferredQty', colName: 'Transafered Qty', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                    { colCode: 'statusName', colName: 'Status', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 }],
            },
            {
                id: 'swims.management',
                meta:{modules: [ModuleType.BRAND_MANAGEMENT,ModuleType.COUNTRY_MANAGEMENT,ModuleType.MALL_MANAGEMENT,
                    ModuleType.WAREHOUSE_LOCATION_MANAGEMENT,ModuleType.ITEM_CATEGORY_MANAGEMENT,ModuleType.ITEM_CATEGORY_VARIANCE_MANAGEMENT,
                    ModuleType.ITEM_STYLE_MANAGEMENT,ModuleType.ITEM_SIZE_MANAGEMENT,ModuleType.STORE_MANAGEMENT, ModuleType.PALLET_MANAGEMENT,
                    ModuleType.SUPPLIER_MANAGEMENT,ModuleType.COLOR_MANAGEMENT,ModuleType.MEASUREMENT_MANAGEMENT,
                    ModuleType.SELL_PRICE_MANAGEMENT,ModuleType.EXCHANGE_RATE_MANAGEMENT,ModuleType.ITEM_MASTER_MANAGEMENT,
                    ModuleType.COLLECTION_MANAGEMENT, ModuleType.TAG_MANAGEMENT
                ]},
                title: 'Management',
                type: 'collapsable',
                icon: 'heroicons_outline:rectangle-group',
                children: [
                    {
                        id: 'swims.management.brand',
                        meta:{modules: [ModuleType.BRAND_MANAGEMENT]},
                        title: 'Brand',
                        type: 'basic',
                        link: '/swims/management/brand',
                        pageField: [
                            { colCode: 'levelOne', colName: 'Level 1', excelName: 'Brand Level 1', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'levelTwo', colName: 'Level 2', excelName: 'Brand Level 2', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'code', colName: 'Code', excelName: 'Brand Code', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'brandWebsite', colName: 'Brand Website', excelName: 'Brand Website', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'startingNumber', colName: 'Starting Number', excelName: 'Starting Number', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'isActive', colName: 'Active', excelName: 'Active', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 }],
                    },
                    {
                        id: 'swims.management.country',
                        meta:{modules: [ModuleType.COUNTRY_MANAGEMENT]},
                        title: 'Country',
                        type: 'basic',
                        link: '/swims/management/country',
                        pageField: [
                            { colCode: 'countryCode', colName: 'Code', excelName: 'Country Code', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'countryName', colName: 'Name', excelName: 'Country Name', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'currencyCode', colName: 'Currency Code', excelName: 'Currency Code', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'currencyName', colName: 'Currency Name', excelName: 'Currency Name', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'symbol', colName: 'Currency Symbol', excelName: 'Currency Symbol', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'isActive', colName: 'Active', excelName: 'Active', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 }],
                    },
                    {
                        id: 'swims.management.mall',
                        meta:{modules: [ModuleType.MALL_MANAGEMENT]},
                        title: 'Mall',
                        type: 'basic',
                        link: '/swims/management/mall',
                        pageField: [
                            { colCode: 'name', colName: 'Name', excelName: 'Mall Name', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'countryCurrency.countryCode', colName: 'Country', excelName: 'Country Code', joiningKey: 'countryCurrencyId', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'isActive', colName: 'Active', excelName: 'Active', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 }],
                    },
                    {
                        id: 'swims.management.warehouse',
                        title: 'Warehouse Location',
                        meta:{modules: [ModuleType.WAREHOUSE_LOCATION_MANAGEMENT]},
                        type: 'basic',
                        link: '/swims/management/warehouse',
                        pageField: [
                            { colCode: 'locationCode', colName: 'Location Code', excelName: 'Warehouse Location Code', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'countryCurrency.countryCode', colName: 'Country', excelName: 'Country Code', joiningKey: 'countryCurrencyId', requiredForUpload: true, hidden: true, checked: true, sortable: true, width: 200 },
                            { colCode: 'countryCurrency.countryName', colName: 'Country / Level 0', excelName: 'Warehouse Level 0', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'levelOne', colName: 'Level 1', excelName: 'Warehouse Level 1', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'levelTwo', colName: 'Level 2', excelName: 'Warehouse Level 2', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'levelThree', colName: 'Level 3', excelName: 'Warehouse Level 3', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'isActive', colName: 'Active', excelName: 'Active', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 }],
                    },
                    {
                        id: 'swims.management.itemcategory',
                        meta:{modules: [ModuleType.ITEM_CATEGORY_MANAGEMENT]},
                        title: 'Item Category',
                        type: 'basic',
                        link: '/swims/management/itemcategory',
                        pageField: [
                            { colCode: 'itemCategoryVarianceManagement.levelOne', colName: 'Level 1', excelName: 'Item Category Level 1', joiningKey: 'levelOne', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'levelTwo', colName: 'Level 2', excelName: 'Item Category Level 2', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'name', colName: 'Name', excelName: 'Item Category Name', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'code', colName: 'Code', excelName: 'Item Category Code', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'isActive', colName: 'Active', excelName: 'Active', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 }],
                    },
                    {
                        id: 'swims.management.itemcategoryvariance',
                        meta:{modules: [ModuleType.ITEM_CATEGORY_VARIANCE_MANAGEMENT]},
                        title: 'Item Category Variance',
                        type: 'basic',
                        link: '/swims/management/itemcategoryvariance',
                        pageField: [
                            { colCode: 'levelOne', colName: 'Level 1', excelName: 'Item Category Level 1', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'variancePercentage', colName: 'Variance', excelName: 'Variance', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'isActive', colName: 'Active', excelName: 'Active', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 }],
                    },
                    {
                        id: 'swims.management.itemstyle',
                        meta:{modules: [ModuleType.ITEM_STYLE_MANAGEMENT]},
                        title: 'Item Style',
                        type: 'basic',
                        link: '/swims/management/itemstyle',
                        pageField: [
                            { colCode: 'name', colName: 'Name', excelName: 'Item Style Name', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'isActive', colName: 'Active', excelName: 'Active', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 }],
                    },
                    {
                        id: 'swims.management.itemsize',
                        meta:{modules: [ModuleType.ITEM_SIZE_MANAGEMENT]},
                        title: 'Item Size',
                        type: 'basic',
                        link: '/swims/management/itemsize',
                        pageField: [
                            { colCode: 'code', colName: 'Size', excelName: 'Size', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'uk', colName: 'UK', excelName: 'UK', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'eur', colName: 'EUR', excelName: 'EUR', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'isActive', colName: 'Active', excelName: 'Active', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 }],
                    },
                    {
                        id: 'swims.management.store',
                        meta:{modules: [ModuleType.STORE_MANAGEMENT]},
                        title: 'Store',
                        type: 'basic',
                        link: '/swims/management/store',
                        pageField: [
                            { colCode: 'code', colName: 'Code', excelName: 'Store Code', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'name', colName: 'Name', excelName: 'Store Name', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'brands.name', colName: 'Brands', excelName: 'Brands', requiredForUpload: false, hidden: false, checked: true, sortable: false, width: 200 },
                            { colCode: 'brand.code', colName: 'Brand Code', excelName: 'Brand Code', joiningKey: 'brandId', requiredForUpload: true, hidden: true, checked: true, sortable: false, width: 200 },
                            { colCode: 'mall.name', colName: 'Mall', excelName: 'Mall Name', joiningKey: 'mallId', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'size', colName: 'Size', excelName: 'Store Size', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'address', colName: 'Address', excelName: 'Store Address', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'isActive', colName: 'Active', excelName: 'Active', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 }],
                    },
                    {
                        id: 'swims.management.supplier',
                        meta:{modules: [ModuleType.SUPPLIER_MANAGEMENT]},
                        title: 'Supplier',
                        type: 'basic',
                        link: '/swims/management/supplier',
                        pageField: [
                            { colCode: 'code', colName: 'Code', excelName: 'Supplier Code', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'name', colName: 'Name', excelName: 'Supplier Name', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'brand.code', colName: 'Brand', excelName: 'Brand Code', joiningKey: 'brandId', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'brand.levelTwo', colName: 'Brand', excelName: 'Brand Name', requiredForUpload: false, hidden: true, checked: true, sortable: true, width: 200 },
                            { colCode: 'country.countryCode', colName: 'Country', excelName: 'Country Code', joiningKey: 'countryId', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'currency.currencyCode', colName: 'Default Currency', excelName: 'Currency Code', joiningKey: 'currencyId', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'isActive', colName: 'Active', excelName: 'Active', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 }],
                    },
                    {
                        id: 'swims.management.color',
                        meta:{modules: [ModuleType.COLOR_MANAGEMENT]},
                        title: 'Color',
                        type: 'basic',
                        link: '/swims/management/color',
                        pageField: [
                            { colCode: 'family', colName: 'Color Family', excelName: 'Color Family', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'name', colName: 'Color', excelName: 'Color', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'code', colName: 'Color Code', excelName: 'Color Code', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'number', colName: 'Color Number', excelName: 'Color Number', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'isActive', colName: 'Active', excelName: 'Active', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 }],
                    },
                    {
                        id: 'swims.management.measurement',
                        meta:{modules: [ModuleType.MEASUREMENT_MANAGEMENT]},
                        title: 'Measurement',
                        type: 'basic',
                        link: '/swims/management/measurement',
                        pageField: [
                            { colCode: 'itemCategory.code', colName: 'Item Category', excelName: 'Item Category Code', joiningKey: 'itemCategoryId', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'itemCategory.name', colName: 'Item Category', excelName: 'Item Category Name', requiredForUpload: false, hidden: true, checked: true, sortable: true, width: 200 },
                            { colCode: 'measurementTypes.typeName', colName: 'Types', excelName: 'Measurement Type Name', requiredForUpload: true, hidden: false, checked: true, sortable: false, width: 200 },
                            { colCode: 'measurementTypes.incrementalValue', colName: 'Types', excelName: 'Measurement Type Incremental Value', requiredForUpload: true, hidden: true, checked: true, sortable: false, width: 200 },
                            { colCode: 'isActive', colName: 'Active', excelName: 'Active', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 }],
                    },
                    {
                        id: 'swims.management.sellprice',
                        meta:{modules: [ModuleType.SELL_PRICE_MANAGEMENT]},
                        title: 'Sell Price',
                        type: 'basic',
                        link: '/swims/management/sellprice',
                        pageField: [
                            { colCode: 'name', colName: 'Price Category', excelName: 'Price Category', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'countrySellPrices.countryCode', colName: 'Country Code', excelName: 'Country Code', joiningKey: 'countryCurrencyId', requiredForUpload: true, hidden: true, checked: true, sortable: true, width: 200 },
                            { colCode: 'countrySellPrices.symbol', colName: 'Currency Symbol', excelName: 'Currency Symbol', requiredForUpload: false, hidden: true, checked: true, sortable: true, width: 200 },
                            { colCode: 'countrySellPrices.price', colName: 'Sell Prices', excelName: 'Sell Price', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'isActive', colName: 'Active', excelName: 'Active', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 }],
                    },
                    {
                        id: 'swims.management.pallet',
                        meta:{modules: [ModuleType.PALLET_MANAGEMENT]},
                        title: 'Pallet',
                        type: 'basic',
                        link: '/swims/management/pallet',
                        pageField: [
                            { colCode: 'palletNumber', colName: 'Pallet Number', excelName: 'Pallet Number', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'warehouseLocation.locationCode', colName: 'Warehouse', excelName: 'Warehouse Location Code', joiningKey: 'warehouseLocationId', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'isVirtual', colName: 'Virtual', excelName: 'Virtual', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'isActive', colName: 'Active', excelName: 'Active', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                        ],
                    },
                    {
                        id: 'swims.management.exchangerate',
                        meta:{modules: [ModuleType.EXCHANGE_RATE_MANAGEMENT]},
                        title: 'Exchange Rate',
                        type: 'basic',
                        link: '/swims/management/exchangerate',
                        pageField: [
                            { colCode: 'countryCurrency.countryCode', colName: 'Currency', excelName: 'Country Code', joiningKey: 'countryCurrencyId', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'countryCurrency.currencyName', colName: 'Currency', excelName: 'Currency Name', requiredForUpload: false, hidden: true, checked: true, sortable: true, width: 200 },
                            { colCode: 'code', colName: 'Currency Exchange Code', excelName: 'Currency Exchange Codes', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'rate', colName: 'Exchange Rate VS $1 SGD', excelName: 'Exchange Rate VS $1 SGD', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'isActive', colName: 'Active', excelName: 'Active', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 }],
                    },
                    {
                        id: 'swims.management.itemmaster.article',
                        meta:{modules: [ModuleType.ITEM_MASTER_MANAGEMENT]},
                        title: 'Item Master',
                        type: 'basic',
                        link: '/swims/management/itemmaster',
                        pageField: [
                            { colCode: 'number', colName: 'Article Number', excelName: 'Article Number', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'itemName', colName: 'Item Name', excelName: 'Item Name', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'brand.code', colName: 'Brand', excelName: 'Brand Code', joiningKey: 'brandId', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'brand.levelTwo', colName: 'Brand', excelName: 'Brand Name', requiredForUpload: false, hidden: true, checked: true, sortable: true, width: 200 },
                            { colCode: 'itemCategory.code', colName: 'Item Category', excelName: 'Item Category Code', joiningKey: 'itemCategoryId', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'itemCategory.name', colName: 'Item Category', excelName: 'Item Category Name', requiredForUpload: false, hidden: true, checked: true, sortable: true, width: 200 },
                            { colCode: 'supplier.code', colName: 'Supplier', excelName: 'Supplier Code', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'supplier.name', colName: 'Supplier', excelName: 'Supplier Name', requiredForUpload: false, hidden: true, checked: true, sortable: true, width: 200 },
                            { colCode: 'supplierItemCode', colName: 'Supplier Item Code', excelName: 'Supplier Item Code', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'itemStyle.name', colName: 'Item Style', excelName: 'Item Style', joiningKey: 'itemStyleId', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'tagArticle', colName: 'Tags', excelName: 'Tags', requiredForUpload: true, hidden: false, checked: true, sortable: false, width: 200 },
                            { colCode: 'sampleSource', colName: 'Sample Source', excelName: 'Sample Source', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'materialComposition', colName: 'Material Composition', excelName: 'Material Composition', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'remarks', colName: 'Remarks', excelName: 'Remarks', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'isActive', colName: 'Active', excelName: 'Active', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 }],
                    },
                    {
                        id: 'swims.management.itemmaster.sku',
                        meta:{modules: [ModuleType.ITEM_MASTER_MANAGEMENT]},
                        title: 'Item Master',
                        type: 'basic',
                        link: '/swims/management/itemmaster',
                        hide: true,
                        pageField: [
                            { colCode: 'article.number', colName: 'Article Number', excelName: 'Article Number', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'article.itemName', colName: 'Item Name', excelName: 'Item Name', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'article.brand.code', colName: 'Brand', excelName: 'Brand Code', joiningKey: 'brandId', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'article.brand.levelTwo', colName: 'Brand', excelName: 'Brand Name', requiredForUpload: false, hidden: true, checked: true, sortable: true, width: 200 },
                            { colCode: 'article.itemCategory.code', colName: 'Item Category', excelName: 'Item Category Code', joiningKey: 'itemCategoryId', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'article.itemCategory.name', colName: 'Item Category', excelName: 'Item Category Name', requiredForUpload: false, hidden: true, checked: true, sortable: true, width: 200 },
                            { colCode: 'article.supplier.code', colName: 'Supplier', excelName: 'Supplier Code', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'article.supplier.name', colName: 'Supplier', excelName: 'Supplier Name', requiredForUpload: false, hidden: true, checked: true, sortable: true, width: 200 },
                            { colCode: 'article.supplierItemCode', colName: 'Supplier Item Code', excelName: 'Supplier Item Code', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'article.itemStyle.name', colName: 'Item Style', excelName: 'Item Style', joiningKey: 'itemStyleId', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'article.tagArticle', colName: 'Tags', excelName: 'Tags', requiredForUpload: false, hidden: false, checked: true, sortable: false, width: 200 },
                            { colCode: 'article.sampleSource', colName: 'Sample Source', excelName: 'Sample Source', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'article.materialComposition', colName: 'Material Composition', excelName: 'Material Composition', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'article.remarks', colName: 'Remarks', excelName: 'Remarks', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'skuCode', colName: 'SKU', excelName: 'SKU Code', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'color.name', colName: 'Color', excelName: 'Color Name', joiningKey: 'colorId', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'itemSize.code', colName: 'Item Size', excelName: 'Item Size', joiningKey: 'itemSizeId', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'countryCurrency.countryCode', colName: 'Country', excelName: 'Cost Country Code', joiningKey: 'costCountryCurrencyId', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'costPrice', colName: 'Cost Price', excelName: 'Cost Price', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'priceCategory.name', colName: 'Price Category', excelName: 'Price Category', joiningKey: 'priceCategoryId', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'countryCurrency.countryCode', colName: 'Country', excelName: 'Sell Price Country Code', joiningKey: 'countryCurrencyId', requiredForUpload: true, hidden: true, downloadHidden: true, checked: true, sortable: true, width: 200 },
                            { colCode: 'itemCountrySellPrices', colName: 'Sell Prices', excelName: 'Sell Prices', requiredForUpload: true, hidden: false, checked: true, sortable: false, width: 200 },
                            { colCode: 'isActive', colName: 'Active', excelName: 'Active', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 }],
                    },
                    {
                        id: 'swims.management.itemmaster.add',
                        meta:{modules: [ModuleType.ITEM_MASTER_MANAGEMENT]},
                        title: 'Item Master',
                        type: 'basic',
                        link: '/swims/management/itemmaster/add',
                        hide: true,
                        pageField: [
                            { colCode: 'number', colName: 'Article Number', excelName: 'Article Number', requiredForUpload: false, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'brand.code', colName: 'Brand', excelName: 'Brand Code', joiningKey: 'brandId', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'brand.levelTwo', colName: 'Brand', excelName: 'Brand Name', requiredForUpload: false, hidden: true, checked: true, sortable: true, width: 200 },
                            { colCode: 'itemCategory.code', colName: 'Item Category', excelName: 'Item Category Code', joiningKey: 'itemCategoryId', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'itemCategory.name', colName: 'Item Category', excelName: 'Item Category Name', requiredForUpload: false, hidden: true, checked: true, sortable: true, width: 200 },
                            { colCode: 'supplier.code', colName: 'Supplier', excelName: 'Supplier Code', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'supplier.name', colName: 'Supplier', excelName: 'Supplier Name', requiredForUpload: false, hidden: true, checked: true, sortable: true, width: 200 },
                            { colCode: 'supplierItemCode', colName: 'Supplier Item Code', excelName: 'Supplier Item Code', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'itemStyle.name', colName: 'Item Style', excelName: 'Item Style', joiningKey: 'itemStyleId', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'tagArticle', colName: 'Tags', excelName: 'Tags', requiredForUpload: true, hidden: false, checked: true, sortable: false, width: 200 },
                            { colCode: 'sampleSource', colName: 'Sample Source', excelName: 'Sample Source', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'materialComposition', colName: 'Material Composition', excelName: 'Material Composition', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'remarks', colName: 'Remarks', excelName: 'Remarks', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'isActive', colName: 'Active', excelName: 'Active', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 }],
                    },
                    {
                        id: 'swims.management.collection',
                        meta:{modules: [ModuleType.COLLECTION_MANAGEMENT]},
                        title: 'Collection',
                        type: 'basic',
                        link: '/swims/management/collection',
                        pageField: [
                            { colCode: 'collectionName', colName: 'Name', excelName: 'Collection', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'brand.code', colName: 'Brand', excelName: 'Brand Code', joiningKey: 'brandId', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'brand.levelTwo', colName: 'Brand', excelName: 'Brand Name', requiredForUpload: false, hidden: true, checked: true, sortable: true, width: 200 },
                            { colCode: 'actualArticleNumber', colName: 'Article', excelName: 'Article Number', requiredForUpload: true, hidden: false, checked: true, sortable: false, width: 200 },
                            { colCode: 'color.name', colName: 'Color', excelName: 'Color Name', joiningKey: 'colorId', requiredForUpload: false, hidden: false, checked: true, sortable: false, width: 200 },
                            { colCode: 'launchDate', colName: 'Launch Date', excelName: 'Launch Date', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'isActive', colName: 'Active', excelName: 'Active', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 }],
                    },
                    {
                        id: 'swims.management.tag',
                        meta:{modules: [ModuleType.TAG_MANAGEMENT]},
                        title: 'Tag',
                        type: 'basic',
                        link: '/swims/management/tag',
                        pageField: [
                            { colCode: 'name', colName: 'Name', excelName: 'Tag Name', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 },
                            { colCode: 'isActive', colName: 'Active', excelName: 'Active', requiredForUpload: true, hidden: false, checked: true, sortable: true, width: 200 }],
                    },
                ],
            },
        ],
    }
];
